import { nanoid } from 'nanoid';

// HEAD DATA
export const head = {
  title: 'Kodeus', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const hero = {
  title: '',
  name: 'Sander',
  subtitle: "I'm a full stack freelance developer",
  cta: 'Get to know me',
  company: '@Kodeus',
};

// ABOUT DATA
export const about = {
  img: 'avatar.jpg',
  paragraphOne:
    'I’m a freelance developer with active knowledge in multiple programming languages. I gained lots of experience in both front and backend development from working at web/communication agencies and on various freelance projects.',
  paragraphTwo:
    'Having accumulated years of Symfony experience as a senior web developer, I felt it was time for something new in 2017. Frontend/mobile frameworks like Ionic, Angular, React and React-native piqued my interest and I got eager to learn more about them.',
  resume: null, // if no resume, the button will not show up
};

// PROJECTS DATA
export const projects = [
  {
    id: nanoid(),
    img: 'itp.jpg',
    title: 'In The Pocket',
    info: 'Working as a react native developer for multiple agency clients.',
    web: 'http://inthepocket.com',
  },
  {
    id: nanoid(),
    img: 'zimmo_project.jpg',
    title: 'Zimmo',
    info:
      'Started as Symfony developer working on the main Zimmo website. After a while, started on the new Ionic Angular mobile app.',
    info2: '',
    web: 'https://www.zimmo.be/nl/',
    app: 'https://apps.apple.com/nl/developer/zimmo-be/id390434622',
  },
  {
    id: nanoid(),
    img: 'cumulio_project.jpg',
    title: 'Cumul.io',
    info:
      'Created a react and react native library for embedding cumul.io dashboards inside a react (native) project.',
    info2: '',
    web: 'https://cumul.io/main',
  },
  {
    id: nanoid(),
    img: 'gifthings.jpeg',
    title: 'Gifthings',
    info:
      'Gifthings is a handy app to create your wish list for special occasions. Is your list ready? Then easily share it with your friends and family.',
    info2: 'You can also view their lists. So you always know what they want!',
    web: 'https://gifthings.me',
    app: 'https://apps.apple.com/be/app/gifthings/id1139488995',
  },
  {
    id: nanoid(),
    img: 'kunstmaan.jpeg',
    title: 'Kunstmaan',
    info: 'I worked on a various of Symfony and React projects for multiple agency clients.',
    web: 'https://kunstmaan.be',
  },
];

// CONTACT DATA
export const contact = {
  cta: 'Interested in working together? Awesome!',
  btn: '',
  email: 'sander@kodeus.be',
};

// FOOTER DATA
export const footer = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/goos_sander',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/sandergo90/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/sandergo90',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
